import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { useMount, createGlobalState } from 'react-use' 

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'

const ThreeAcross = (props) => {
	const { hovering, setHovering, images } = props;
		
	const renderImages = () => {
		return images.map((item, i) => {
			return (
				<Image
					key={i}
					src={item}
				>
					{(src, loading) => {
						return (
							<LoadedImage
								src={src} 
								// style={{opacity: loading ? 0 : 1}}
							/>
						)
					}}
				</Image> 
			)
		})
	}

	return (
		<Wrapper>
            <Grid
                onMouseLeave={() => setHovering(false)}
            >
			    {renderImages()}
            </Grid>
		</Wrapper>
	)
}

// Shared

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const LoadedImage = styled.img`
	/* transition: opacity 0.45s ease; */
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 1440px;
	height: 100%;
	padding: 0 105px;
`

const Grid = styled.div`
    display: flex;

	${LoadedImage} {
        width: 33.33%;
        height: auto;
        padding: 0 30px;
	}
`

ThreeAcross.wrapper = css`

`

export default ThreeAcross

import { 
    Stacked,
    ThreeAcross,
} from './templates'

export const resolveImages = (layout) => {
    // Map layout strings to blocks

    const images = {
        'stacked': Stacked,
        'three-across': ThreeAcross
    }
    
    return images[layout]
}



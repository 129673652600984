import React, { useState, useEffect } from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'
import { useMount, useMedia } from 'react-use'
import Marquee from 'react-marquee-slider'
import times from 'lodash/times'

import { isClient } from '../../utils'
import { media, breakpoints } from '../../styles/utils'
import { container, padding, hoverState } from '../../styles/global'

const Ticker = (props) => {
  const { content } = props
  const isMobile = useMedia(`(max-width: ${breakpoints['phone']}px)`)

  return (
    <Wrapper>
      <Marquee velocity={isMobile ? 4 : 20} direction={'rtl'}>
        {times(16, Number).map((id) => (
          <Description
            key={id}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        ))}
      </Marquee>
    </Wrapper>
  )
}

const Description = styled.div``

const Wrapper = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid black;
  background: white;

  ${Description} {
    margin-right: 42px;

    &,
    * {
      font-weight: 500;
      font-size: 22px;
      line-height: 1;
    }
  }
`

export default Ticker

import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { resolveImages } from './utils';

const Images = (props) => {
	const { layout, hovering } = props;
    const ImagesLayout = resolveImages(layout);

	if (!ImagesLayout) return <></>

	return (
		<Wrapper
			layout={layout}
			hovering={hovering}
			styles={ImagesLayout && ImagesLayout.wrapper}
			{...props}
		>
			{ImagesLayout && (
				<ImagesLayout {...props}/>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	transition: opacity 0.45s ease;
	
	/* Images specific container styles */

	${props => {
		if (props.styles) return props.styles
	}}
`

export default Images
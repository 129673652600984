import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { useMount, createGlobalState } from 'react-use' 

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage } from '../../../styles/global'

const STACK_DELAY = 500; // ms

const Stacked = (props) => {
	const { hovering, setHovering, images, slug } = props;
	const [activeIndex, setActiveIndex] = useState(-1)

    useMount(() => {
		console.log(images?.length)

		setTimeout(() => {
			setActiveIndex(0)
		}, images?.length > 1 ? 300 : 0);
	})
	
    useEffect(() => {        
		if (activeIndex == -1) return;

        const timer = setTimeout(() => {
            setNextIndex()
        }, STACK_DELAY);

        return () => clearTimeout(timer);   
	}, [activeIndex]);
	
	const setNextIndex = () => {
		if (activeIndex + 1 !== images.length) {
			setActiveIndex(activeIndex + 1)
		}
	}
 		
	const renderImages = () => {
		return images.map((item, i) => {
			return (
				<Image
					key={i}
					src={item}
				>
					{(src, loading) => {
						return (
							<LoadedImage
								src={src} 
								style={{opacity: activeIndex > -1 && activeIndex >= i ? 1 : 0}}
							/>
						)
					}}
				</Image> 
			)
		})
	}

	return (
		<Wrapper
			slug={slug}
		>
			{renderImages()}
		</Wrapper>
	)
}

// Shared

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const LoadedImage = styled.img`
	/* transition: opacity 0.45s ease; */
`

const Wrapper = styled.div`
	position: relative;
	width: 920px;
	height: 750px;

	${props => {
		if (props.slug === 'landscapes')
			return css`
				width: 100%;
			`
	}}

	${Image}, ${LoadedImage} {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 100%;
		max-height: 100%;
	}
`

Stacked.wrapper = css`

`

export default Stacked

import React, { useState, useContext } from 'react'
import { navigate, Link } from 'gatsby'
import { useWindowScroll, createGlobalState } from 'react-use';
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'

import { HeaderContext } from './HeaderContext'

import logo from '../../assets/images/form-logo.svg'

const Header = (props) => {
	const { isVisible } = useContext(HeaderContext)

	return (
		<AnimatePresence>
			{isVisible && (
				<Wrapper
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
				>
					<Container>
						<Logo src={logo}/>
					</Container>
				</Wrapper>
			)}
		</AnimatePresence>
	)
}

const Wrapper = styled(motion.div)`
	z-index: 1;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 50px;
	background: white;
	border-bottom: 1px solid black;
`


const Container = styled.div`
	${container}
	${padding}
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`

const Logo = styled.img``

export default Header
